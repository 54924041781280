<template>
<div style="    width: 100%;">
  <html xmlns="http://www.w3.org/1999/xhtml"><head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>{{ formData.title }}</title>



  </head>
  <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0" data-new-gr-c-s-check-loaded="14.1115.0" data-gr-ext-installed="">
  <center>
    <table id="bodyTable" align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%">
      <tbody><tr>
        <td id="bodyCell" align="center" valign="top">

          <!-- BEGIN CONTENT // -->
          <table v-if="formData.header_image" border="0" cellpadding="0" cellspacing="0" width="600" id="headerTable">
            <tbody><tr>
              <td valign="top">
                <a href="https://www.ifcia-antoun.com" title="IFCIA - HADI ANTOUN" target="_blank" class="image-link"><img alt="La Lettre de l'IFCIA - Dr Hadi Antoun, Paris - Décembre 2019" :src="imagePath(formData.header_image)" width="600" class="image-full"></a>
              </td>
            </tr>
            </tbody></table>
          <table border="0" cellpadding="0" cellspacing="0" width="600" id="contentTable">

            <tbody>
            <tr v-if="selectedMainNews && selectedMainNews.length > 0">
              <td valign="top">
                <div><a :href="websitePath(selectedMainNews[0].url)" title="Lire la suite" target="_blank" class="image-link"><img alt="" :src="formData.main_news_image ? imagePath(formData.main_news_image) : imagePath(selectedMainNews[0].image)" width="600" class="image-full"></a></div>
                <div class="leftpadding rightpadding toppadding bottompadding">
                  <div class="fontsize16 fontcolorIFCIA helvetica fontweight600 toppadding bottompadding">{{ formattedText(selectedMainNews[0].title) }}</div>
                  <div class="fontsize12 fontcolor3B helvetica fontweight400"><p style="text-align: justify;" v-html="selectedMainNews[0].brief "></p></div>
                </div>
                <div class="leftpadding rightpadding bottompadding"><a :href="websitePath(selectedMainNews[0].url)" title="Lire la suite" target="_blank" class="image-link"><img alt="" src="https://medias.ifcia-antoun.com/newsletter/lire-la-suite.png" width="560" class="image-full"></a></div>
              </td>
            </tr>
            <!--            // News-->
            <div v-for="news in selectedNews">
              <tr>
                <td valign="top" class="doublebottompadding toppadding">
                  <div class="leftpadding rightpadding toppadding bottompadding helvetica fontcolorFF bgcolorIFCIA fontsize18 aligncenter">{{ news.category.name }}</div>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <div><a :href="websitePath(news.url)" title="Lire la suite" target="_blank" class="image-link"><img align="center" alt="Illustration" :src="imagePath(news.image)" width="600" class="image-full"></a></div>
                  <div class="leftpadding rightpadding toppadding bottompadding">
                    <div class="fontsize16 fontcolorIFCIA helvetica fontweight600 toppadding bottompadding">{{ news.title }}</div>
                    <div class="fontsize12 fontcolor3B helvetica fontweight400" v-html="news.brief"></div>
                  </div>
                  <div class="leftpadding rightpadding bottompadding"><a href="https://www.ifcia-antoun.com/fr/actualites/0160-report-hadi-antoun-chirurgie-guidee-session-september-2021" title="Lire la suite" target="_blank" class="image-link"><img align="center" alt="" src="https://medias.ifcia-antoun.com/newsletter/lire-la-suite.png" width="560" class="image-full"></a></div>
                </td>
              </tr>
            </div>


<!--            // PUBLICATIONS-->
            <tr v-if="selectedPublications && selectedPublications.length > 0">
              <td valign="top" class="doublebottompadding toppadding">
                <div class="leftpadding rightpadding toppadding bottompadding helvetica fontcolorFF bgcolorIFCIA fontsize18 aligncenter">PUBLICATIONS</div>
              </td>
            </tr>

            <tr v-for="publication in selectedPublications">
              <td valign="top">
                <div><a :href="websitePath(publication.url)" title="Lire la suite" target="_blank" class="image-link"><img align="center" alt="Illustration" :src="imagePath(publication.image)" width="600" class="image-full"></a></div>
                <div class="leftpadding rightpadding toppadding bottompadding">
                  <div class="fontsize16 fontcolorIFCIA helvetica fontweight600 toppadding bottompadding">{{ publication.title }}</div>
                  <div class="fontsize12 fontcolor3B helvetica fontweight400"><p style="text-align: justify;">{{ publication.brief }}</p></div>
                </div>
                <div class="leftpadding rightpadding bottompadding"><a :href="websitePath(publication.url)" title="Lire la suite" target="_blank" class="image-link"><img align="center" alt="" src="https://medias.ifcia-antoun.com/newsletter/lire-la-suite.png" width="560" class="image-full"></a></div>
              </td>
            </tr>


<!--            //Training Sessions-->
            <tr v-if="selectedTrainingSessions && selectedTrainingSessions.length > 0">
              <td valign="top" class="doublebottompadding toppadding">
                <div class="leftpadding rightpadding toppadding bottompadding helvetica fontcolorFF bgcolorIFCIA fontsize18 aligncenter">AGENDA</div>
              </td>
            </tr>
            <tr v-for="session in selectedTrainingSessions">
              <td valign="top">
                <div><a :href="websitePath(session.training.url)" title="En savoir plus" target="_blank" class="image-link"><img align="center" alt="Illustration" :src="imagePath(session.training.slider_image)" width="600" class="image-full"></a></div>
                <div class="leftpadding rightpadding toppadding bottompadding helvetica alignright">
                  <div class="fontsize16 fontcolorIFCIA fontweight600 toppadding">{{ session.training.title }}</div>
                  <div class="fontsize16 fontcolor3B fontweight600 bottompadding">Prochaine session: {{ session.days }}</div>
                  <div class="toppadding bottompadding"><a :href="websitePath(session.training.url)" title="Découvrir la formation" target="_blank" class="image-link"><img style="margin-bottom: 15px;" align="right" alt="Découvrir la formation" src="https://medias.ifcia-antoun.com/newsletter/decouvrir-la-formation.png" width="200" height="36" class="image-small"></a></div>
                </div>
              </td>
            </tr>
            <tr v-if="formData.agendas && formData.agendas.length > 0">
              <td valign="top">
                <div class="leftpadding rightpadding toppadding bottompadding helvetica fontcolor3B fontsize16 alignright">-------</div>
                <div class="leftpadding rightpadding toppadding bottompadding helvetica fontcolorIFCIA fontsize18 fontweight600 alignright">Prochaines conférences du Dr Hadi Antoun</div>
              </td>
            </tr>
            <tr v-for="agenda in formData.agendas">
              <td valign="top">
                <div class="leftpadding rightpadding toppadding bottompadding helvetica alignright">
                  <div class="fontsize16 fontcolorIFCIA" v-if="agenda.hasOwnProperty('date')"> {{ agenda.date }} </div>
                  <div class="fontsize18 fontcolor3B" v-if="agenda.hasOwnProperty('titre')"> {{ agenda.titre }} </div>
                  <div class="fontsize18 fontcolor3B"v-if="agenda.hasOwnProperty('conference')"> {{ agenda.conference }} </div>
                  <div class="fontsize11 fontcolor3B" v-if="agenda.hasOwnProperty('lieu')">{{ agenda.lieu }}</div>
                  <div class="fontsize11 fontcolor3B" v-if="agenda.hasOwnProperty('conference')">{{ agenda.place }}</div>
                </div>
              </td>
            </tr>

            </tbody></table>
          <table border="0" cellpadding="0" cellspacing="0" width="600" id="followTable">
            <tbody><tr>
              <td valign="top">
                <div class="leftpadding rightpadding toppadding">
                  <div class="toppadding bottompadding"><div class="divider"><span></span></div></div>
                  <p class="fontsize15 fontcolor60 helvetica aligncenter">RESTEZ CONNECTÉS</p>
                </div>
                <div class="leftpadding rightpadding bottompadding aligncenter fontcolorIFCIA helvetica">
                  <a href="https://www.facebook.com/institutifciaparis" title="Facebook" target="_blank" class="image-link"><img align="center" alt="Facebook" src="https://medias.ifcia-antoun.com/newsletter/facebook.png" width="40" height="40" class="image-small"></a>
                  <a href="https://twitter.com/IFCIA_Paris" title="Twitter" target="_blank" class="image-link"><img align="center" alt="Twitter" src="https://medias.ifcia-antoun.com/newsletter/twitter.png" width="40" height="40" class="image-small"></a>
                  <a href="https://www.youtube.com/user/IFCIAParis" title="Youtube" target="_blank" class="image-link"><img align="center" alt="Youtube" src="https://medias.ifcia-antoun.com/newsletter/youtube.png" width="40" height="40" class="image-small"></a>
                  <a href="https://www.linkedin.com/company/10199166/" title="Linkedin" target="_blank" class="image-link"><img align="center" alt="Linkedin" src="https://medias.ifcia-antoun.com/newsletter/linkedin.png" width="40" height="40" class="image-small"></a>
                </div>
              </td>
            </tr>
            </tbody></table>
          <table border="0" cellpadding="0" cellspacing="0" width="600" id="footerTable">
            <tbody><tr>
              <td valign="top">
                <div><img align="center" alt="Nos partenaires" src="https://medias.ifcia-antoun.com/newsletter/partenaires-2021-03.png" width="600" class="image-full"></div>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <div class="leftpadding rightpadding toppadding">
                  <p class="fontsize9 aligncenter helvetica fontcolorBE">Copyright © 2003 - {{new Date().getFullYear()}} Institut de Formation en Chirurgie Implantaire Avancée, All rights reserved. Vous recevez cet email parce que vous êtes inscrits à la Lettre de L’IFCIA, que vous êtes un professionnel en implantologie ou que vous avez suivi l’une de nos formations.</p>
                  <p class="fontsize9 aligncenter helvetica fontcolorBE"><i>Enregistrée sous le numéro 11 75 53534 75. Cet enregistrement ne vaut pas agrément de l’Etat.</i></p>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <div class="leftpadding rightpadding">
                  <p class="fontsize9 aligncenter helvetica fontcolorBE"><a href="*|UNSUB|*" title="Se désabonner" target="_blank" class="fontcolorBE">Se désabonner</a></p>
                </div>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <div class="leftpadding rightpadding bottompadding">
                  <p class="fontsize12 aligncenter helvetica fontcolorBE">IFCIA - HADI ANTOUN<br>11 bis, avenue Mac Mahon 75017 Paris<br>Téléphone : + 33 (0)1 85 09 22 89</p>
                </div>
              </td>
            </tr>
            </tbody></table>

          <!-- END CONTENT // -->

        </td>
      </tr>
      </tbody></table>
  </center>

  <div id="chrome_websiteIP" class="chrome_websiteIP_right">213.186.33.40</div></body></html>
</div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  props:{
    formData: {
      type: Object,
        default: function (){
          return {
            title: "",
            header_image: "",
            news_ids: [],
            publication_ids: [],
            agenda_ids: [],
            upcoming_lectures: "",
            main_news_id: [],
            main_news_image: "",
            agendas: [],
          }
      },
    },
    builderData: {
      type: Object,
      default: function (){
        return {
          trainingSessionList: [],
          newsList: [],
          publicationList: [],
        }
      }
    }
  },
  data() {
    return {
      imageUrl : process.env.VUE_APP_SERVER_IMAGE_URL,
      websiteUrl : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },
  computed: {
    selectedNews() {
      return this.builderData?.newsList?.filter(item => JSON.parse(this.formData.news_ids).includes(item.id)) || [];
      return this.builderData.newsList.filter(item => this.formData.news_ids.includes(item.id));
    },
    selectedPublications() {
      return this.builderData?.publicationList?.filter(item => this.formData.publication_ids.includes(item.id)) || [];
      return this.builderData.publicationList.filter(item => this.formData.publication_ids.includes(item.id));
    },
    selectedTrainingSessions() {
      console.log( this.builderData?.trainingSessionList)
      return this.builderData?.trainingSessionList?.filter(item => this.formData.session_ids.includes(item.id)) || [];
      return this.builderData.trainingSessionList.filter(item => this.formData.session_ids.includes(item.id));
    },
    selectedMainNews() {
      return this.builderData?.newsList?.filter(item => item.id === this.formData.main_news_id) || null;
      return this.builderData.newsList.find(item => item.id === this.formData.main_news_id);
    }
  },
  methods: {
    formattedText(htmlText) {
      const element = document.createElement('textarea');
      element.innerHTML = htmlText;
      return element.value;
    },
    imagePath(path){
      if (path && path.startsWith('https://medias.ifcia-antoun.com')){
        return path;
      }
      return  this.imageUrl + path;
    },
    websitePath(path){
      return this.websiteUrl + path;
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  },
}
</script>
<style type="text/css">
body {
  margin: 0;
  padding: 0;
  background-color: #F2F2F2;
  height: 100% !important;
  width: 100% !important;
  line-height: 1.5;
}

table {
  border-collapse: collapse;
  border: 0;
  margin: 0;
  padding: 0;
}

.image-full {
  max-width: 600px;
  border: 0;
  outline: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.divider {
  border-top: #eaeaea 2px solid;
}

.leftpadding {
  padding-left: 20px;
}
.rightpadding {
  padding-right: 20px;
}
.toppadding {
  padding-top: 10px;
}
.bottompadding {
  padding-bottom: 10px;
}

.doubletoppadding {
  padding-top: 20px;
}
.doublebottompadding {
  padding-bottom: 20px;
}

.helvetica {
  font-family: Helvetica;
}

.fontcolorBE {
  color: #BEBEBE;
}
.fontcolorFF {
  color: #FFFFFF;
}
.fontcolor3B {
  color: #3B3B3B;
}
.fontcolor60 {
  color: #606060;
}
.fontcolorIFCIA {
  color: #C51351;
}

.bgcolorIFCIA {
  background-color: #C51351;
}

.fontweight600 {
  font-weight: 600;
}
.fontweight400 {
  font-weight: 400;
}

.fontsize18 {
  font-size: 18px;
}
.fontsize17 {
  font-size: 17px;
}
.fontsize16 {
  font-size: 16px;
}
.fontsize15 {
  font-size: 15px;
}
.fontsize14 {
  font-size: 14px;
}
.fontsize13 {
  font-size: 13px;
}
.fontsize12 {
  font-size: 12px;
}
.fontsize11 {
  font-size: 11px;
}
.fontsize10 {
  font-size: 10px;
}
.fontsize9 {
  font-size: 9px;
}

.aligncenter {
  text-align: center;
}
.alignleft {
  text-align: left;
}
.alignright {
  text-align: right;
}

#bodyTable {
  background-color: #F2F2F2;
  height: 100% !important;
  width: 100% !important;
}

#bodyCell {
  margin: 0;
  padding: 20px;
  height: 100% !important;
  width: 100% !important;
}

#headerTable {
  background-color: #FFFFFF;
}

#contentTable {
  background-color: #FFFFFF;
}

#followTable {
  background-color: #FFFFFF;
}

#footerTable {
  background-color: #343434;
}

</style>
