<template>
  <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html dir="ltr" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr">

  <head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="telephone=no" name="format-detection">
    <title>New Template 2</title>
    <!--[if (mso 16)]><style type="text/css">     a {text-decoration: none;}     </style><![endif]-->
    <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
    <!--[if gte mso 9]><xml> <o:OfficeDocumentSettings> <o:AllowPNG></o:AllowPNG> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml>
    <![endif]-->
    <!--[if !mso]><!-- -->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Licorice&display=swap" rel="stylesheet">
    <!--<![endif]-->

  </head>

  <body data-new-gr-c-s-loaded="14.1131.0"
    style="width:100%;font-family:arial, &apos;helvetica neue&apos;, helvetica, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0">
    <div dir="ltr" class="es-wrapper-color" lang="fr" style="background-color:#FFFFFF">
      <!--[if gte mso 9]><v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t"> <v:fill type="tile" color="#ffffff"></v:fill> </v:background><![endif]-->
      <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" role="none"
        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#FFFFFF">
        <tbody>
          <tr>
            <td valign="top" style="padding:0;Margin:0">
              <table cellpadding="0" cellspacing="0" class="es-header" align="center" role="none"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
                <tbody>
                  <tr>
                    <td align="center" bgcolor="#efefef" style="padding:0;Margin:0;background-color:#efefef">
                      <table bgcolor="#ffffff" class="es-header-body" align="center" cellpadding="0" cellspacing="0"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#ffffff;width:800px"
                        role="none">
                        <tbody>
                          <tr>
                            <td align="left" bgcolor="#333333"
                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-bottom:30px;background-color:#333333">
                              <!--[if mso]><table style="width:760px" cellpadding="0" cellspacing="0"><tr>
                      <td style="width:336px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" align="left" class="es-left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p0r es-m-p20b" valign="top" align="center"
                                      style="padding:0;Margin:0;width:316px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="Margin:0;padding-bottom:10px;padding-left:10px;padding-right:10px;padding-top:30px;font-size:0px">
                                              <a target="_blank" :href="websitePath('')"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#EFEFEF;font-size:12px"><img
                                                  class="logo-img"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/logowhite.png"
                                                  alt="Logo"
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  title="Logo" width="108" height="44"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:20px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td>
                    <td style="width:322px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td align="left" class="es-m-p20b" style="padding:0;Margin:0;width:322px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="Margin:0;padding-bottom:10px;padding-left:10px;padding-right:10px;padding-top:30px"
                                              class="esd-text">
                                              <h3
                                                style="Margin:0;line-height:24px;mso-line-height-rule:exactly; font-size:20px;font-style:normal;font-weight:normal;color:#ffffff">
                                                LA LETTRE DE L’IFCIA</h3>
                                              <span
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;   line-height:18px;color:#ffffff!important;font-size:12px">
                                                Institut De Formation En
                                                Chirurgie Implantaire
                                                Avancee</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:82px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:82px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" bgcolor="#ffffff" style="padding:0;Margin:0"
                                              class="esd-text">
                                              <h1 class="newsletter__item__no"
                                                style="Margin:0;line-height:36px;mso-line-height-rule:exactly; font-size:30px;font-style:normal;font-weight:normal;color:#DA328A">
                                                N°{{ this.formData.numero }}</h1>
                                              <p class="date"
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly; line-height:18px;color:#000000;font-size:12px">
                                                {{ formattedScheduleDate }}</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td align="left" bgcolor="#333333"
                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;background-color:#333333">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p0r" valign="top" align="center"
                                      style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td style="padding:0;Margin:0">
                                              <table cellpadding="0" cellspacing="0" width="100%" class="es-menu"
                                                role="presentation"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tbody>
                                                  <tr class="links">
                                                    <td align="center" valign="top" width="100%" id="esd-menu-id-2"
                                                      style="Margin:0;padding-left:5px;padding-right:5px;padding-top:8px;padding-bottom:8px;border:0">
                                                      <a target="_blank" href="#"
                                                        style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;display:block; color:#EFEFEF;font-size:16px"></a>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="es-content" cellspacing="0" cellpadding="0" align="center" role="none"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
                <tbody>
                  <tr>
                    <td align="center" bgcolor="#efefef" style="padding:0;Margin:0;background-color:#efefef">
                      <table class="es-content-body"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#ffffff;width:800px"
                        cellspacing="0" cellpadding="0" bgcolor="#333333" align="center" role="none">
                        <tbody>
                          <tr>
                            <td align="left" style="padding:0;Margin:0">
                              <table cellspacing="0" cellpadding="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p0r" valign="top" align="center"
                                      style="padding:0;Margin:0;width:800px">
                                      <table width="100%" cellspacing="0" cellpadding="0" bgcolor="#ffffff"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#ffffff"
                                        role="presentation">
                                        <tbody>
                                          <tr v-if="formData.header_image">
                                            <td align="center" style="padding:0;Margin:0;position:relative">
                                              <a target="_blank" :href="websitePath('')"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="banner-img" :src="imagePath(formData.header_image)" alt title
                                                  width="800"
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  height="324"></a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="center" bgcolor="#DA328A"
                                              style="Margin:0;padding-top:30px;padding-bottom:30px;padding-left:40px;padding-right:40px"
                                              class="esd-text">
                                              <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly; line-height:30px;color:#ffffff;font-size:20px">
                                                <em
                                                  style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly; line-height:30px;color:#ffffff;font-size:20px">Hadi
                                                  Antoun repondra a
                                                  toutes les questions de
                                                  Thierry Degorce sur la
                                                  nouvelle edition des
                                                  greffes de sinus en
                                                  implantologie</em>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <!--                  //Selected Main News-->
                          <tr v-if="selectedMainSession">
                            <td class="esdev-adapt-off" align="left"
                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:30px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0;padding-bottom:10px"
                                              class="esd-text">
                                              <h2 class="p_name"
                                                style="Margin:0;line-height:29px;mso-line-height-rule:exactly; font-size:24px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedMainSession.training.title }}</h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" class="es-m-txt-c esd-text"
                                              style="padding:0;Margin:0;padding-bottom:10px">
                                              <p style="margin: 0px; text-size-adjust: none;   line-height: 20px; color: #d3d3d3; font-size: 13px;"
                                                class="p_description"><span style="color:#808080;">{{
                                                  getSanitizedText(selectedMainSession.training.brief) }}</span></p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <!--                  //Select Main News buttons-->
                          <tr v-if="selectedMainSession">
                            <td align="left"
                              style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:17px">
                              <!--[if mso]><table style="width:760px" cellpadding="0" cellspacing="0"><tr><td style="width:189px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:115px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text">
                                              <span class="msohide es-button-border"
                                                style="border-style:solid;border-color:#2CB543;background:#da328a;border-width:0px;display:inline-block;border-radius:0px;width:auto;mso-hide:all">
                                                <a :href="websitePath('trainings/' + selectedMainSession.training.url)"
                                                  class="es-button" target="_blank"
                                                  style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#da328a;border-radius:0px ;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                  <span
                                                    style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff !important;font-size:12px;padding:9px;display:inline-block;background:#da328a;border-radius:0px; font-weight:bold;font-style:normal;line-height:9px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                    INSCRIPTION</span>
                                                </a>
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:0px"></td>
                    <td style="width:571px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:571px;padding-right: 70px;">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text">
                                              <span class="msohide es-button-border"
                                                style="border-style:solid;border-color:#333333;background:#ffffff;border-width:1px;display:inline-block;border-radius:0px;width:auto;mso-hide:all">
                                                <a :href="websitePath('trainings/' + selectedMainSession.training.url)"
                                                  class="es-button" target="_blank"
                                                  style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#333333;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#ffffff;border-radius:0px;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                  <span
                                                    style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#333333!important;font-size:12px;padding:9px;display:inline-block;background:#ffffff;border-radius:0px;font-weight:bold;font-style:normal;line-height:9px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                    EN SAVOIR PLUS</span>
                                                </a>
                                              </span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr v-if="selectedBook">
                            <td align="left"
                              style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-top:40px;padding-bottom:40px"
                                              class="esd-text">
                                              <h1 class="p_name"
                                                style="Margin:0;line-height:36px;mso-line-height-rule:exactly;font-size:30px;font-style:normal;font-weight:normal;color:#333333">
                                                VIE DE L’INSTITUT</h1>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="selectedBook">
                            <td align="left" bgcolor="#fef6f7"
                              style="Margin:0;padding-left:20px;padding-right:20px;padding-top:40px;padding-bottom:40px;background-color:#fef6f7">
                              <!--[if mso]><table style="width:760px" cellpadding="0" cellspacing="0"><tr><td style="width:296px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:296px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:5px;Margin:0;font-size:0px">
                                              <a target="_blank" :href="websitePath(selectedBook.url)"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  :src="imagePath(selectedBook.image)" alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="286" class="adapt-img p_image" height="266"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:444px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:444px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px"
                                              class="esd-text">
                                              <h2 class="p_name"
                                                style="Margin:0;line-height:29px;mso-line-height-rule:exactly;font-size:24px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedBook.title }}
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" class="es-m-txt-c esd-text"
                                              style="padding:0;Margin:0;padding-bottom:10px">
                                              <p style="margin: 0px;text-size-adjust: none;line-height: 20px;color: #808080;font-size: 13px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;"
                                                class="p_description">Cette
                                                {{ getSanitizedText(selectedBook.content) }}
                                              </p>
                                            </td>
                                          </tr>

                                          <tr>
                                            <td align="left" style="padding:0;Margin:0; ">
                                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tbody>
                                                  <tr>
                                                    <td align="left" style="padding:0;Margin:0" class="esd-text">
                                                      <span style="font-size:12px;"><span class="msohide es-button-border"
                                                          style="border-style:solid;border-color:#da328a;background:#fef6f7;border-width:1px;display:inline-block;border-radius:0px;width:auto;mso-hide:all"><a
                                                            :href="imagePath(selectedBook.demo)" class="es-button"
                                                            target="_blank"
                                                            style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#da328a;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#fef6f7;border-radius:0px;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #fef6f7">
                                                            <span
                                                              style="text-decoration: none; text-size-adjust: none; color: #da328a!important; font-size: 12px; padding: 9px; display: inline-block; background: #fef6f7; border-radius: 0px;  font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">
                                                              DOWNLOAD AN EXTRACT
                                                            </span>

                                                          </a></span></span>
                                                      <!--<![endif]-->
                                                    </td>
                                                    <td align="left" style="padding:0;Margin:0  ;   padding-right: 144px;"
                                                      class="esd-text">
                                                      <span style="font-size:12px;"><span class="msohide es-button-border"
                                                          style="border-style:solid;border-color:#333333;background:#da328a;border-width:1px;display:inline-block;border-radius:0px;width:auto;mso-hide:all"><a
                                                            :href="selectedBook.link" class="es-button" target="_blank"
                                                            style="text-decoration: none; text-size-adjust: none; color: #ffffff!important; font-size: 12px; padding: 9px; display: inline-block; background: #da328a; border-radius: 0px;  font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">ORDER</a></span></span>
                                                      <!--<![endif]-->
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>


                                          </tr>
                                          <tr>

                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr v-if="selectedPublicationCategory">
                            <td align="left"
                              style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-bottom:20px;padding-top:40px"
                                              class="esd-text">
                                              <h1 class="p_name"
                                                style="Margin:0;line-height:36px;mso-line-height-rule:exactly;font-size:30px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedPublicationCategory.name }}</h1>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="selectedPublications" v-for="publication in selectedPublications">
                            <td class="esdev-adapt-off" align="left"
                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:30px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" class="es-m-txt-c esd-text"
                                              style="padding:0;Margin:0;padding-bottom:10px">
                                              <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;line-height:20px;color:#da328a;font-size:13px"
                                                class="p_description">{{ getFormattedDate(publication.date) }}</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0;padding-bottom:10px"
                                              class="esd-text">
                                              <h2 class="p_description"
                                                style="Margin:0;line-height:29px;mso-line-height-rule:exactly;font-size:24px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ publication.title }}
                                              </h2>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" class="es-m-txt-c esd-text"
                                              style="padding:0;Margin:0;padding-bottom:10px">
                                              <p style="margin: 0px; text-size-adjust: none;  line-height: 20px; color: #808080; font-size: 13px;"
                                                class="p_description">
                                                {{ getSanitizedText(publication.brief) }}</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td v-if="publication.authors.length > 0" align="left"
                                              class="es-m-txt-c esd-text" style="padding:0;Margin:0;padding-bottom:10px">
                                              <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;line-height:20px;color:#333333;font-size:13px"
                                                class="p_description">
                                                <strong>Auteurs : <span v-for="author in publication.authors">{{
                                                  author.name }}</span> </strong>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text">
                                              <span style="font-size:12px;"><span class="msohide es-button-border"
                                                  style="border-style:solid;border-color:#2CB543;background:#da328a;border-width:0px;display:inline-block;border-radius:0px;width:auto;mso-hide:all"><a
                                                    :href="websitePath('news/'+publication.url)" class="es-button" target="_blank"
                                                    style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#da328a;border-radius:0px;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                    <span
                                                      style="text-decoration: none; text-size-adjust: none; color: #ffffff!important; font-size: 12px; padding: 9px; display: inline-block; background: #da328a; border-radius: 0px;  font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">
                                                      READ
                                                      MORE
                                                    </span>
                                                  </a></span></span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="selectedNewsCategory">
                            <td align="left"
                              style="padding:0;Margin:0;padding-bottom:20px;padding-left:20px;padding-right:20px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="center" valign="top" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center"
                                              style="padding:0;Margin:0;padding-top:40px;padding-bottom:40px;font-size:0">
                                              <table border="0" width="100%" height="100%" cellpadding="0" cellspacing="0"
                                                role="presentation"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="padding:0;Margin:0;border-bottom:2px solid #cccccc;background:none;height:1px;width:100%;margin:0px">
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-bottom:20px;padding-top:40px"
                                              class="esd-text">
                                              <h1 class="p_name"
                                                style="Margin:0;line-height:36px;mso-line-height-rule:exactly;font-size:30px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedNewsCategory.name }}</h1>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <!-- begain of agenda -->
                          <tr v-if="selectedNews" v-for="itemNumber in Math.ceil(selectedNews.length / 2)">
                            <td v-if="selectedNews[2 * itemNumber - 2]" align="left" bgcolor="#ffffff"
                              style="Margin:0;padding-bottom:20px;padding-left:20px;padding-right:20px;padding-top:30px;background-color:#ffffff">
                              <!--[if mso]><table dir="ltr" cellpadding="0" cellspacing="0"><tr><td><table dir="rtl" style="width:760px" cellpadding="0" cellspacing="0"><tr><td dir="ltr" style="width:371px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:371px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;font-size:0px">
                                              <a target="_blank" :href="websitePath('news/'+selectedNews[2 * itemNumber - 2].url)"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img"
                                                  :src="imagePath(selectedNews[2 * itemNumber - 2].image)" alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="371" height="247"></a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-bottom:10px;padding-top:15px"
                                              class="esd-text">
                                              <h2 class="p_description"
                                                style="Margin:0;line-height:19px;mso-line-height-rule:exactly;font-size:16px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedNews[2 * itemNumber - 2].title }}</h2>
                                            </td>
                                          </tr>
                                          <!--                              <tr>-->
                                          <!--                                <td align="left"-->
                                          <!--                                    class="es-m-txt-c esd-text"-->
                                          <!--                                    style="padding:0;Margin:0;padding-top:10px;padding-bottom:15px">-->
                                          <!--                                  <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:20px;color:#333333;font-size:13px"-->
                                          <!--                                     class="p_description">-->
                                          <!--                                    <strong>Auteurs : Thierry-->
                                          <!--                                      Degroce, Hadi-->
                                          <!--                                      Antoun</strong>-->
                                          <!--                                  </p>-->
                                          <!--                                </td>-->
                                          <!--                              </tr>-->
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text">
                                              <span style="font-size:12px;"><span class="msohide es-button-border"
                                                  style="border-style:solid;border-color:#2CB543;background:#da328a;border-width:0px;display:inline-block;border-radius:0px;width:auto;mso-hide:all"><a
                                                    :href="websitePath('news/'+selectedNews[2 * itemNumber - 2].url)"
                                                    class="es-button" target="_blank"
                                                    style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#da328a;border-radius:0px;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                    <span
                                                      style="text-decoration: none; text-size-adjust: none; color: #ffffff!important; font-size: 12px; padding: 9px; display: inline-block; background: #da328a; border-radius: 0px;  font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">
                                                      READ
                                                      MORE
                                                    </span>

                                                  </a></span></span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td dir="ltr" style="width:20px"></td>
                    <td v-if="selectedNews[2 * itemNumber - 1]" dir="ltr" style="width:369px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr v-if="selectedNews[2 * itemNumber - 1]">
                                    <td align="left" style="padding:0;Margin:0;width:369px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;font-size:0px">
                                              <a target="_blank" :href="websitePath('news/' + selectedNews[2 * itemNumber - 1].url)"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img"
                                                  :src="imagePath(selectedNews[2 * itemNumber - 1].image)" alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="369" height="246"></a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-bottom:10px;padding-top:15px"
                                              class="esd-text">
                                              <h2 class="p_description"
                                                style="Margin:0;line-height:19px;mso-line-height-rule:exactly;font-size:16px;font-style:normal;font-weight:normal;color:#333333">
                                                {{ selectedNews[2 * itemNumber - 1].title }}</h2>
                                            </td>
                                          </tr>
                                          <!--                              <tr>-->
                                          <!--                                <td align="left"-->
                                          <!--                                    class="es-m-txt-c esd-text"-->
                                          <!--                                    style="padding:0;Margin:0;padding-top:10px;padding-bottom:15px">-->
                                          <!--                                  <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:20px;color:#333333;font-size:13px"-->
                                          <!--                                     class="p_description">-->
                                          <!--                                    <strong>Auteurs : Thierry-->
                                          <!--                                      Degroce, Hadi-->
                                          <!--                                      Antoun</strong>-->
                                          <!--                                  </p>-->
                                          <!--                                </td>-->
                                          <!--                              </tr>-->
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text">
                                              <span style="font-size:12px;"><span class="msohide es-button-border"
                                                  style="border-style:solid;border-color:#2CB543;background:#da328a;border-width:0px;display:inline-block;border-radius:0px;width:auto;mso-hide:all"><a
                                                    :href="websitePath('news/' +selectedNews[2 * itemNumber - 1].url)"
                                                    class="es-button" target="_blank"
                                                    style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff;font-size:13px;padding:10px 20px 10px 20px;display:inline-block;background:#da328a;border-radius:0px;font-weight:bold;font-style:normal;line-height:16px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #EFEFEF">
                                                    <span
                                                      style="text-decoration: none; text-size-adjust: none; color: #ffffff!important; font-size: 12px; padding: 9px; display: inline-block; background: #da328a; border-radius: 0px;  font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">
                                                      READ
                                                      MORE</span>


                                                  </a></span></span>
                                              <!--<![endif]-->
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr v-if="selectedNewsCategory">
                            <td class="esdev-adapt-off" align="left"
                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:30px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p0r" align="center" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center"
                                              style="padding:0;Margin:0;padding-top:40px;padding-bottom:40px;font-size:0">
                                              <table border="0" width="100%" height="100%" cellpadding="0" cellspacing="0"
                                                role="presentation"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      style="padding:0;Margin:0;border-bottom:2px solid #cccccc;background:none;height:1px;width:100%;margin:0px">
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" bgcolor="#cccccc" style="padding:20px;Margin:0;background-color:#cccccc">
                              <!--[if mso]><table style="width:760px" cellpadding="0" cellspacing="0"><tr><td style="width:159px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:25px;font-size:0px">
                                              <a target="_blank" href="https://www.biobank.fr/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/biobank.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="32"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:159px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:15px;font-size:0px">
                                              <a target="_blank" href="https://www.3mc-concept.fr/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/3mcconcept.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="65"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td>
                    <td style="width:159px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td align="left" class="es-m-p20b" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:25px;font-size:0px">
                                              <a target="_blank" href="https://www.biotech-dental.com/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/biotechdental.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="28"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:124px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td align="left" class="es-m-p20b" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center"
                                              style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px;font-size:0px">
                                              <a target="_blank" href="https://www.dentalforce.fr/index.php"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/dentalforce.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="89"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:35px"></td>
                    <td style="width:124px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:30px;font-size:0px">
                                              <a target="_blank" href="https://www.ekler.fr/en/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/ekler.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="42"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td align="left" bgcolor="#cccccc" style="padding:20px;Margin:0;background-color:#cccccc">
                              <!--[if mso]><table style="width:760px" cellpadding="0" cellspacing="0"><tr>
                      <td style="width:161px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:126px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:40px;font-size:0px">
                                              <a target="_blank" href="https://www.geistlich.fr/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/geistlich.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="126" height="40"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:159px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:40px;font-size:0px">
                                              <a target="_blank" href="https://www.hufriedy.eu/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/hufriedy.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="27"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td>
                    <td style="width:159px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td align="left" class="es-m-p20b" style="padding:0;Margin:0;width:124px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center"
                                              style="padding:0;Margin:0;padding-bottom:10px;padding-top:40px;font-size:0px">
                                              <a target="_blank" href="https://www.ipp-pharma.net/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/ipppharma.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="124" height="19"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td class="es-hidden" style="padding:0;Margin:0;width:35px"></td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td>
                    <td style="width:123px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td align="left" class="es-m-p20b" style="padding:0;Margin:0;width:123px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:30px;font-size:0px">
                                              <a target="_blank" href="https://www.meisinger.de/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/meisinger.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="123" height="41"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:35px"></td><td style="width:123px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:123px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="center" style="padding:0;Margin:0;padding-top:40px;font-size:0px">
                                              <a target="_blank" href="https://www.dentiste.philips.fr/"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="adapt-img2"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/philips.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="123" height="25"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding:0;Margin:0">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="center" valign="top" style="padding:0;Margin:0;width:800px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:40px;font-size:0px">
                                              <a target="_blank" :href="websitePath('')"
                                                style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                  class="logo-img"
                                                  src="https://zqnnwd.stripocdn.email/content/guids/CABINET_12f5e9f41367bfcf9412c8af91f510e3f5073cda0f26f757bfcaba8206d9c9cc/images/logocolored.png"
                                                  alt
                                                  style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"
                                                  width="127" height="52"></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="left"
                              style="Margin:0;padding-left:15px;padding-right:15px;padding-top:35px;padding-bottom:40px">
                              <!--[if mso]><table style="width:770px" cellpadding="0" cellspacing="0"><tr>
                      <td style="width:375px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                <tbody>
                                  <tr>
                                    <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:375px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" class="h-auto esd-text" height="65"
                                              style="padding:0;Margin:0">
                                              <h3
                                                style="Margin:0;line-height:24px;mso-line-height-rule:exactly;font-size:20px;font-style:normal;font-weight:normal;color:#808080">
                                                11 bis, avenue Mac
                                                Mahon<br>75017 Paris</h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left" class="h-auto esd-text" style="padding:0;Margin:0"
                                              height="45">
                                              <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;line-height:21px;color:#696969;font-size:14px">
                                                contact@ifcia-antoun.com</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td><td style="width:20px"></td><td style="width:375px" valign="top"><![endif]-->
                              <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:375px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="right" class="h-auto esd-text" style="padding:0;Margin:0"
                                              height="65">
                                              <h3
                                                style="Margin:0;line-height:24px;mso-line-height-rule:exactly;font-size:20px;font-style:normal;font-weight:normal;color:#808080">
                                                Restez Connecter</h3>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="right"
                                              style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px;padding-left:10px;font-size:0">
                                              <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social"
                                                role="presentation"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tbody>
                                                  <tr>
                                                    <td align="center" valign="top"
                                                      style="padding:0;Margin:0;padding-right:35px">
                                                      <a target="_blank" href="#"
                                                        style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                          title="Facebook"
                                                          src="https://zqnnwd.stripocdn.email/content/guids/CABINET_3122c1566abab464362e9d4009324ac45a98d86dfa5588007ca2c3dc31e4e874/images/ifciasnewsletterfb.png"
                                                          alt="Fb" width="24" height="24"
                                                          style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"></a>
                                                    </td>
                                                    <td align="center" valign="top"
                                                      style="padding:0;Margin:0;padding-right:35px">
                                                      <a target="_blank" href="#"
                                                        style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                          title="Twitter"
                                                          src="https://zqnnwd.stripocdn.email/content/guids/CABINET_3122c1566abab464362e9d4009324ac45a98d86dfa5588007ca2c3dc31e4e874/images/ifciasnewsletterlinkedin.png"
                                                          alt="Tw" width="24" height="24"
                                                          style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"></a>
                                                    </td>
                                                    <td align="center" valign="top" style="padding:0;Margin:0;">
                                                      <a target="_blank" href="#"
                                                        style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img
                                                          title="Instagram"
                                                          src="https://zqnnwd.stripocdn.email/content/guids/CABINET_3122c1566abab464362e9d4009324ac45a98d86dfa5588007ca2c3dc31e4e874/images/ifciasnewsletterinstagram.png"
                                                          alt="Inst" width="24" height="24"
                                                          style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic"></a>
                                                    </td>

                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!--[if mso]></td></tr></table><![endif]-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table cellpadding="0" cellspacing="0" class="es-footer" align="center" role="none"
                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
                <tbody>
                  <tr>
                    <td align="center" bgcolor="#efefef" style="padding:0;Margin:0;background-color:#efefef">
                      <table class="es-footer-body" align="center" cellpadding="0" cellspacing="0"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#ffffff;width:800px"
                        bgcolor="#ffffff" role="none">
                        <tbody>
                          <tr>
                            <td align="left"
                              style="Margin:0;padding-top:5px;padding-left:20px;padding-right:20px;padding-bottom:35px">
                              <table cellpadding="0" cellspacing="0" width="100%" role="none"
                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                <tbody>
                                  <tr>
                                    <td align="left" style="padding:0;Margin:0;width:760px">
                                      <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                        <tbody>
                                          <tr>
                                            <td align="left" style="padding:0;Margin:0" class="esd-text"><span
                                                style="font-size:12px;"><span class="msohide es-button-border"
                                                  style="border-style:solid;border-color:#da328a;background:#ffffff;border-width:2px;display:inline-block;border-radius:0px;width:auto;mso-hide:all">

                                                  <span
                                                    style="text-decoration: none; text-size-adjust: none; color: #da328a; font-size: 12px; padding: 9px; display: inline-block; background: #ffffff; border-radius: 0px; font-weight: bold; font-style: normal; line-height: 9px; width: auto; text-align: center;">
                                                    SE
                                                    DÉSABONNER
                                                  </span>
                                                </span></span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-bottom:10px;padding-top:40px"
                                              class="esd-text">
                                              <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;line-height:18px;color:#808080;font-size:12px">
                                                Copyright © 2003 - 2023
                                                Institut de Formation en
                                                Chirurgie Implantaire
                                                Avancée, All rights
                                                reserved. Vous recevez cet
                                                email parce que vous êtes
                                                inscrits à la Lettre de
                                                L’IFCIA, que vous êtes un
                                                professionnel en
                                                implantologie ou que vous
                                                avez suivi l’une de nos
                                                formations.</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td align="left"
                                              style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px"
                                              class="esd-text">
                                              <p
                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;line-height:18px;color:#333333;font-size:12px">
                                                <em>Enregistrée sous le
                                                  numéro 11 75 53534 75.
                                                  Cet enregistrement ne
                                                  vaut pas agrément de
                                                  l’Etat.</em>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>

  </html>
</template>
<script>
import { TableColumn } from 'element-ui'
import { NewPagination as NLPagination, Switch as LSwitch } from '@/components'
import { DeleteModal as DeleteModal } from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  props: {
    formData: {
      type: Object,
      default: function () {
        return {
          title: "",
          schedule: null,
          header_image: "",
          news_category_id: '',
          publication_category_id: '',
          news_ids: [],
          publication_ids: [],
          // agenda_ids: [],
          // upcoming_lectures: "",
          // main_news_id: [],
          main_session_id: '',
          book_id: '',
          // main_news_image: "",
          // agendas: [],
        }
      },
    },
    builderData: {
      type: Object,
      default: function () {
        return {
          trainingSessionList: [],
          bookList: [],
          newsList: [],
          newsCategoryList: [],
          publicationList: [],
          publicationCategoryList: [],
          newsletterNumber: 0,
        }
      }
    }
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_SERVER_IMAGE_URL,
      websiteUrl: 'https://ifcia.dlclients.xyz/',
    }

  },
  computed: {
    selectedNews() {
      return this.builderData?.newsList?.filter(item => this.formData.news_ids.includes(item.id)) || [];
      return this.builderData.newsList.filter(item => this.formData.news_ids.includes(item.id));
    },
    selectedPublications() {
      return this.builderData?.publicationList?.filter(item => this.formData.publication_ids.includes(item.id)) || [];
      return this.builderData.publicationList.filter(item => this.formData.publication_ids.includes(item.id));
    },
    selectedTrainingSessions() {
      console.log(this.builderData?.trainingSessionList?.filter(item => this.formData.session_ids.includes(item.id)))
      return this.builderData?.trainingSessionList?.filter(item => this.formData.session_ids.includes(item.id)) || [];
      return this.builderData.trainingSessionList.filter(item => this.formData.session_ids.includes(item.id));
    },
    // selectedMainNews() {
    //   return this.builderData?.newsList?.find(item => item.id === this.formData.main_news_id) || null;
    //   return this.builderData.newsList.find(item => item.id === this.formData.main_news_id);
    // },
    selectedMainSession() {
      return this.builderData?.trainingSessionList?.find(item => item.id === this.formData.main_session_id) || null;
    },
    selectedBook() {
      return this.builderData?.bookList?.find(item => item.id === this.formData.book_id) || null;
    },
    selectedNewsCategory() {
      return this.builderData?.newsCategoryList?.find(item => item.id === this.formData.news_category_id) || null;
    },
    selectedPublicationCategory() {
      return this.builderData?.publicationCategoryList?.find(item => item.id === this.formData.publication_category_id) || null;
    },
    formattedScheduleDate() {
      if (this.formData && this.formData.schedule) {
        return new Date(this.formData.schedule).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      }
      return new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    }
  },
  methods: {
    getFormattedDate(date) {
      const months = [
        'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
        'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
      ];

      const [year, month, day] = date.split('-');
      const formattedMonth = months[parseInt(month) - 1];

      return `${parseInt(day)} ${formattedMonth} ${year}`;
    },
    getSanitizedText(text) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = text;
      return tempElement.textContent;
    },
    imagePath(path) {
      if (path && path.startsWith('https://medias.ifcia-antoun.com')) {
        return path;
      }
      return this.imageUrl + path;
    },
    websitePath(path) {
      return this.websiteUrl + path;
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr)
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  },
}
</script>
<style type="text/css">
#outlook a {
  padding: 0;
}

.es-button {
  mso-style-priority: 100 !important;
  text-decoration: none !important;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

.ii a[href] {
  color: unset !important;
  text-decoration: none !important;
}

.es-desk-hidden {
  display: none;
  float: left;
  overflow: hidden;
  width: 0;
  max-height: 0;
  line-height: 0;
  mso-hide: all;
}

[data-ogsb] .es-button.es-button-1 {
  padding: 20px !important;
}

@media only screen and (max-width:600px) {

  p,
  ul li,
  ol li,
  a {
    line-height: 150% !important
  }

  h1,
  h2,
  h3,
  h1 a,
  h2 a,
  h3 a {
    line-height: 120%
  }

  h1 {
    font-size: 30px !important;
    /* text-align: center */
  }

  h2 {
    font-size: 20px !important;
    /* text-align: center */
  }

  h3 {
    font-size: 20px !important;
  }

  .es-header-body h1 a,
  .es-content-body h1 a,
  .es-footer-body h1 a {
    font-size: 30px !important
  }

  .es-header-body h2 a,
  .es-content-body h2 a,
  .es-footer-body h2 a {
    font-size: 20px !important
  }

  .es-header-body h3 a,
  .es-content-body h3 a,
  .es-footer-body h3 a {
    font-size: 20px !important
  }

  .es-menu td a {
    font-size: 13px !important
  }

  .es-header-body p,
  .es-header-body ul li,
  .es-header-body ol li,
  .es-header-body a {
    font-size: 16px !important
  }

  .es-content-body p,
  .es-content-body ul li,
  .es-content-body ol li,
  .es-content-body a {
    font-size: 16px !important
  }

  .es-footer-body p,
  .es-footer-body ul li,
  .es-footer-body ol li,
  .es-footer-body a {
    font-size: 16px !important
  }

  .es-infoblock p,
  .es-infoblock ul li,
  .es-infoblock ol li,
  .es-infoblock a {
    font-size: 12px !important
  }

  *[class="gmail-fix"] {
    display: none !important
  }

  .es-m-txt-c,
  .es-m-txt-c h1,
  .es-m-txt-c h2,
  .es-m-txt-c h3 {
    /* text-align: center !important */
  }

  .es-m-txt-r,
  .es-m-txt-r h1,
  .es-m-txt-r h2,
  .es-m-txt-r h3 {
    text-align: right !important
  }

  .es-m-txt-l,
  .es-m-txt-l h1,
  .es-m-txt-l h2,
  .es-m-txt-l h3 {
    text-align: left !important
  }

  .es-m-txt-r img,
  .es-m-txt-c img,
  .es-m-txt-l img {
    display: inline !important
  }

  .es-button-border {
    display: inline-block !important
  }

  a.es-button,
  button.es-button {
    font-size: 12px !important;
    display: inline-block !important
  }

  .es-adaptive table,
  .es-left,
  .es-right {
    width: 100% !important
  }

  .es-content table,
  .es-header table,
  .es-footer table,
  .es-content,
  .es-footer,
  .es-header {
    width: 100% !important;
    max-width: 600px !important
  }

  .es-adapt-td {
    display: block !important;
    width: 100% !important
  }

  .adapt-img {
    width: 100% !important;
    height: auto !important
  }

  .adapt-img2 {
    padding: 25px 0 !important;
    width: 70% !important;
    height: auto !important
  }

  .banner-img {
    width: 100% !important;
    height: auto !important
  }

  .es-m-p0 {
    padding: 0px !important
  }

  .es-m-p0r {
    padding-right: 0px !important
  }

  .es-m-p0l {
    padding-left: 0px !important
  }

  .es-m-p0t {
    padding-top: 0px !important
  }

  .es-m-p0b {
    padding-bottom: 0 !important
  }

  .es-m-p20b {
    padding-bottom: 20px !important
  }

  .es-mobile-hidden,
  .es-hidden {
    display: none !important
  }

  tr.es-desk-hidden,
  td.es-desk-hidden,
  table.es-desk-hidden {
    width: auto !important;
    overflow: visible !important;
    float: none !important;
    max-height: inherit !important;
    line-height: inherit !important
  }

  tr.es-desk-hidden {
    display: table-row !important
  }

  table.es-desk-hidden {
    display: table !important
  }

  td.es-desk-menu-hidden {
    display: table-cell !important
  }

  .es-menu td {
    width: 1% !important
  }

  table.es-table-not-adapt,
  .esd-block-html table {
    width: auto !important
  }

  table.es-social {
    display: inline-block !important
  }

  table.es-social td {
    display: inline-block !important
  }

  .es-desk-hidden {
    display: table-row !important;
    width: auto !important;
    overflow: visible !important;
    max-height: inherit !important
  }

  .h-auto {
    height: auto !important
  }
}
</style>
