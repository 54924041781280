<template>

  <div class="row" ref="partnerForm">
   <newsletter-template v-if="formData.old_id" :formData="formData" :builderData="builderData"></newsletter-template>
   <new-newsletter-template v-else :formData="formData" :builderData="builderData"></new-newsletter-template>
  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import NewsletterTemplate from "../emailTemplates/NewsletterTemplate";
import NewNewsletterTemplate from "../emailTemplates/NewNewsletterTemplate";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    NewNewsletterTemplate,
    NewsletterTemplate,
    LSwitch,
    FgSelect,
    PrimeUploader,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        title: "",
        header_image: "",
        news_ids: [],
        publication_ids: [],
        session_ids: [],
        upcoming_lectures: "",
        main_news_id: [],
        main_news_image: "",
        schedule: '',
        agendas: [],
      },
      builderData: {
        trainingSessionList: [],
        newsList: [],
        publicationList: [],
      },
    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.partnerForm
    });

    let data = {}
    this.axios.post("newsletter-templates/builder", data).then((response) => {
      this.builderData.newsList = response.data.news;
      this.builderData.publicationList = response.data.publications;
      this.builderData.trainingSessionList = response.data.trainingSessions
        .map(obj => ({...obj, title: obj.training.title + ' Start at : ' + obj.start_date}));

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Template");
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Template");
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("newsletter-templates/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Template Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      this.formData.html = document.getElementById('newsletterTemplate').innerHTML;
      if (this.editMode === true) {
        request = this.axios.put("newsletter-templates/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Template Updated Successfully");
      } else {
        request = this.axios.post("newsletter-templates/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Template Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        // this.$router.push("/newsletter-templates/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems, initObject) {
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

  }
}
</script>

<style>
</style>
